<template>
  <div id="help">
    <div class="helpTitle">
      <div class="title">Help</div>
    </div>
    <el-row id="help-content">
      <el-col :span="22" :push="1">
        <el-card>
          <el-col :span="18">
            <div id="help-text">
              <div id="what" ref="what" class="help-section">
                <h2 class="help-title">What is DeepOMe?</h2>
                <p>
                  DeepOMe is a useful tool that predicts the sites of
                  2′-O-methylations. By utilizing Deep Learning algorithm, we
                  trained a hybrid CNN and BLSTM model based on the published
                  2′-O-methylation sites. The online service was provided. This
                  page is prepared as the usage for the online service.
                </p>
              </div>
              <div id="how" ref="how" class="help-section">
                <h2 class="help-title">How do I use it?</h2>
                <p>
                  Firstly, to use DeepOMe you will need to input a nucleotide
                  sequence or upload data file in FASTA format. To obtain the
                  predicted result with the anticipated success rate, we
                  recommend using the entire sequence of the query RNA rather
                  than its fragment.
                </p>
                <div id="example">
                  <p id="example-title"><b>An example for FASTA format:</b></p>
                  <pre id="example-seq">{{ fasta }}</pre>
                </div>
              </div>
              <div id="thresold" ref="thresold" class="help-section">
                <h2 class="help-title">What is the prediction threshold?</h2>
                <p>
                  The prediction models of DeepOMe were trained using Deep
                  Learning algorithm. After training the prediction models, we
                  carried out 4-,6-,8- and 10-fold cross-validation to validate
                  the prediction performance. To balance the prediction
                  accuracy, we selected three thresholds with high, medium and
                  low stringencies based on the evaluation results. The detailed
                  performance under these three thresholds on 10-fold cross
                  validation was presented as follow:
                </p>
                <div id="help-table">
                  <el-table :data="table" cell-class-name="performance-cell">
                    <el-table-column
                      label-class-name="t-header"
                      label="Thresold"
                      prop="index"
                    ></el-table-column>
                    <el-table-column
                      label-class-name="t-header"
                      prop="cutoff"
                      label="Cutoff"
                    ></el-table-column>
                    <el-table-column
                      label-class-name="t-header"
                      prop="precision"
                      label="Precision"
                    ></el-table-column>
                    <el-table-column
                      label-class-name="t-header"
                      prop="specificity"
                      label="Specificity"
                    ></el-table-column>
                    <el-table-column
                      label-class-name="t-header"
                      prop="sensitivity"
                      label="Sensitivity"
                    ></el-table-column>
                    <el-table-column
                      label-class-name="t-header"
                      prop="accuracy"
                      label="Accuracy"
                    ></el-table-column>
                    <el-table-column
                      label-class-name="t-header"
                      prop="mcc"
                      label="MCC"
                    ></el-table-column>
                  </el-table>
                </div>
              </div>
              <div id="having-trouble" ref="trouble" class="help-section">
                <h2 class="help-title">
                  I'm having trouble with DeepOMe that isn't addressed on this
                  page. What should I do?
                </h2>
                <p>
                  If you are having trouble with DeepNitro please contact the
                  two major authors:
                  <a href="mailto:renjian.sysu@gmail.com">Dr. Jian Ren</a> and
                  <a href="mailto:renjian.sysu@gmail.com">Dr. Yubin Xie</a>. We
                  will try to resolve it.
                </p>
              </div>
            </div>
          </el-col>
          <el-col :span="6" id="help-menu">
            <h2 id="navigation-title">Navigation</h2>
            <el-menu
              id="navigation-menu"
              @select="jumpTo"
              active-text-color="#000"
            >
              <el-menu-item index="what"><p>What is DeepOMe?</p></el-menu-item>
              <el-menu-item index="how"><p>How do I use it?</p></el-menu-item>
              <el-menu-item index="thresold"
                ><p>What is the prediction threshold?</p></el-menu-item
              >
              <el-menu-item index="trouble"
                ><p>Having troubles</p></el-menu-item
              >
            </el-menu>
            <!-- <ul>
              <li><a href="#what">What is DeepOMe?</a></li>
              <li><a href="#how">How do I use it?</a></li>
              <li><a href="#thresold">What is the prediction threshold?</a></li>
              <li><a href="#having-trouble">Having troubles</a></li>
            </ul> -->
          </el-col>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { exampleSeq } from "../assets/js/ExampleSeq";
export default {
  data() {
    return {
      fasta: exampleSeq,
// `>NM_003377
// ATGAGCCGCCTGCCCGCCAGCCCGGGCCCAGCCCCCCGCCGCCCCCGCCGTCCCCGCCGCCGCTGCCCGCCGCCACCG
// GCCGCCCGCCCGCCCGGCTCCTCCGGCCGCCTCCGCTGCGCTGCGCTGCGCTGCCTGCACCCAGGGCTCGGGAGGGGG
// CCGCGGAGGAGTCGCCCCCCGCGCCCGGCCCCCGCCCGCCGCGCCCGGGCCCGCGCCATGGGGCTCTGGCTGTCGCCG
// CCCCCCGCGCCGCCGGGCTAGGGCGATGCGGGCGCCCCCGGCGGGCGGCCCCGGCGGGCACCATGAGCCCTCTGCTCC
// GCCGCCTGCTGCTCGCCGCACTCCTGCAGCTGGCCCCCGCCCAGGCCCCTGTCTCCCAGCCTGATGCCCCTGGCCACC
// AGAGGAAAGTGGTGTCATGGATAGATGTGTATACTCGCGCTACCTGCCAGCCCCGGGAGGTGGTGGTGCCCTTGACTG
// TGGAGCTCATGGGCACCGTGGCCAAACAGCTGGTGCCCAGCTGCGTGACTGTGCAGCGCTGTGGTGGCTGCTGCCCTG
// ACGATGGCCTGGAGTGTGTGCCCACTGGGCAGCACCAAGTCCGGATGCAGATCCTCATGATCCGGTACCCGAGCAGTC
// AGCTGGGGGAGATGTCCCTGGAAGAACACAGCCAGTGTGAATGCAGACCTAAAAAAAAGGACAGTGCTGTGAAGCCAG
// ACAGGGCTGCCACTCCCCACCACCGTCCCCAGCCCCGTTCTGTTCCGGGCTGGGACTCTGCCCCCGGAGCACCCTCCC
// CAGCTGACATCACCCATCCCACTCCAGCCCCAGGCCCCTCTGCCCACGCTGCACCCAGCACCACCAGCGCCCTGACCC
// CCGGACCTGCCGCTGCCGCTGCCGACGCCGCAGCTTCCTCCGTTGCCAAGGGCGGGGCTTAGAGCTCAACCCAGACAC
// CTGCAGGTGCCGGAAGCTGCGAAGGTGACACATGGCTTTTCAGACTCAGCAGGGTGACTTGCCTCAGAGGCTATATCC
// CAGTGGGGGAACAAAGAGGAGCCTGGTAAAAAACAGCCAAGCCCCCAAGACCTCAGCCCAGGCAGAAGCTGCTCTAGG
// ACCTGGGCCTCTCAGAGGGCTCTTCTGCCATCCCTTGTCTCCCTGAGGCCATCATCAAACAGGACAGAGTTGGAAGAG
// GAGACTGGGAGGCAGCAAGAGGGGTCACATACCAGCTCAGGGGAGAATGGAGTACTGTCTCAGTTTCTAACCACTCTG
// TGCAAGTAAGCATCTTACAACTGGCTCTTCCTCCCCTCACTAAGAAGACCCAAACCTCTGCATAATGGGATTTGGGCT
// TTGGTACAAGAACTGTGACCCCCAACCCTGATAAAAGAGATGGAAGGAGCTGTC
// >NM_032807.5
// CTGCTTTCAAGGTGTCTAGATACAGAGTGAAGAATGTCACAGTAGGAAGTGCACTGGACTAGGGGAGTCAGGAGACCC
// GGGTTCTTGTGTTGGCACCATCACTCATGAGCTACGAGGTGACTTCAGGCTGCCATTGGACCTGTCAAGTGCCTGAGT
// CATGTGATAATGGGCTACATTGCGCAGGGCCCCTGGGCCATCTCCACAGGAGATGCCAGAGGACGAGTGCCCACTTGC
// TGGTCTTCACAGAGCACGCTGAAATGAGACGGTTTAAGCGGAAGCATCTTACTGCCATTGACTGCCAGCATTTGGCTC
// GGAGTCACTTGGCTGTGACCCAGCCCTTCGGTCAAAGATGGACAAACAGAGATCCGAACCATGGTCTCTATCCTAAAC
// CGAGAACAAAAAGAGGGAGTAGGGGTCAGGGAAGTCAAAGATGCATCCCTGAGTTCTTCCTAGCAGGCAAGCAGCCGT
// GCACCAATGACATGGCCAAAAGCAATTCTGTTGGCCAGGACAGCTGTCAGGACTCTGAGGGTGACATGATCTTTCCTG
// CAGAGAGCAGCTGTGCACTGCCTCAGGAAGGCAGTGCAGGGCCGGGCTCACCAGGGTCTGCCCCGCCCTCCAGGAAGC
// GGTCTTGGTCCTCTGAGGAAGAGAGTAACCAGGCTACCGGGACCAGCCGGTGGGATGGAGTTTCTAAGAAAGCTCCAC
// GGCACCATTTGTCTGTGCCATGCACAAGGCCTAGGGAGGCCAGGCAAGAAGCAGAGGACAGTACGTCTCGGCTCTCTG
// CGGAGTCTGGTGAAACCGACCAAGATGCTGGGGACGTGGGTCCTGATCCCATTCCTGACTCATACTATGGGCTTCTTG
// GGACCTTGCCCTGCCAGGAAGCACTGAGCCACATTTGCAGCCTGCCTAGTGAGGTCCTGAGGCACGTGTTTGCCTTCC
// TCCCGGTGGAAGACCTCTATTGGAACCTGAGCTTGGTGTGCCACTTGTGGAGGGAGATCATCAGTGACCCGCTGTTCA
// TTCCTTGGAAGAAGCTGTACCATCGATACCTGATGAATGAAGAGCAAGCTGTCAGCAAAGTGGACGGCATCCTGTCTA
// ACTGTGGCATAGAAAAGGAGTCAGACCTGTGTGTGCTGAACCTCATACGATACACAGCCACCACTAAGTGCTCTCCGA
// GTGTGGATCCCGAGAGGGTGCTGTGGAGTCTGAGGGACCACCCCCTCCTCCCCGAGGCTGAGGCGTGTGTGCGGCAAC
// ACCTCCCCGACCTCTACGCTGCTGCCGGGGGTGTCAACATCTGGGCCCTGGTGGCGGCTGTGGTGCTCCTCTCCAGCA
// GTGTGAATGACATCCAGCGACTGCTCTTCTGCCTCCGGAGACCCAGCTCCACGGTGACCATGCCAGATGTCACCGAGA
// CCCTGTACTGCATAGCCGTGCTTCTCTACGCCATGAGGGAGAAGGGGATTAACATCAGCAATAGGATTCACTACAACA
// TTTTCTATTGCCTATATCTTCAGGAGAATTCCTGCACTCAGGCCACAAAAGTTAAAGAGGAGCCATCTGTCTGGCCAG
// GCAAGAAAACCATCCAACTTACACATGAACAACAGCTGATTCTGAATCACAAGATGGAACCTCTCCAGGTGGTGAAAA
// TTATGGCCTTTGCCGGCACTGGGAAGACCTCAACGCTGGTCAAGTATGCAGAGAAGTGGTCTCAGAGCAGGTTTCTGT
// ATGTGACATTCAACAAGAGCATCGCAAAGCAGGCCGAACGCGTCTTCCCCAGCAACGTCATCTGCAAAACCTTCCACT
// CCATGGCCTACGGGCACATAGGGCGGAAGTACCAGTCAAAGAAGAAGTTGAATCTCTTCAAGTTAACACCCTTCATGG
// TCAACTCCGTCCTTGCTGAAGGGAAGGGTGGATTCATAAGAGCCAAGCTTGTGTGTAAGACTCTAGAAAACTTCTTTG
// CCTCGGCTGACGAAGAGCTGACCATTGATCACGTGCCTATTTGGTGTAAGAACAGCCAAGGACAGAGAGTCATGGTTG
// AGCAGAGTGAAAAACTGAATGGTGTCCTTGAAGCGAGCCGCCTCTGGGATAACATGCGGAAGCTGGGGGAGTGCACAG
// AAGAGGCGCACCAGATGACTCATGACGGCTACTTGAAACTCTGGCAGCTGAGCAAGCCTTCGCTGGCCTCTTTTGACG
// CCATCTTTGTGGATGAGGCCCAGGACTGCACACCAGCTATCATGAACATAGTTCTGTCTCAGCCATGTGGGAAAATCT
// TTGTAGGGGACCCGCACCAGCAGATCTATACCTTCCGGGGTGCGGTCAACGCCCTGTTCACAGTGCCCCACACCCACG
// TCTTCTATCTCACGCAGAGTTTTCGGTTTGGTGTGGAAATAGCTTATGTGGGAGCTACTATCTTGGATGTTTGCAAGA
// GAGTCAGGAAAAAGACTTTGGTTGGAGGAAACCATCAGAGTGGCATTAGAGGTGACGCAAAGGGGCAAGTGGCCTTGT
// TGTCCCGGACCAACGCCAACGTGTTTGATGAGGCCGTACGGGTGACGGAAGGGGAATTCCCTTCAAGGATACATTTGA
// TTGGGGGGATTAAATCATTTGGATTGGACAGAATCATTGATATTTGGATCCTTCTTCAGCCAGAGGAAGAACGGAGGA
// AACAAAACCTCGTCATTAAAGACAAATTTATCAGAAGATGGGTGCACAAAGAAGGCTTTAGTGGCTTCAAGAGGTATG
// TGACCGCTGCCGAGGACAAGGAGCTTGAAGCCAAGATCGCAGTTGTTGAAAAGTATAACATCAGGATTCCAGAGCTGG
// TGCAAAGGATAGAAAAATGCCATATAGAAGATTTGGACTTTGCAGAGTACATTCTGGGCACTGTGCACAAAGCCAAAG
// GCCTGGAGTTTGACACTGTGCATGTTTTGGATGATTTTGTGAAAGTGCCTTGTGCCCGGCATAACCTGCCCCAGCTTC
// CGCACTTCAGAGTTGAGTCATTTTCTGAGGATGAATGGAATTTACTGTATGTTGCAGTAACTCGAGCCAAGAAGCGTC
// TCATCATGACCAAATCATTGGAAAACATTTTGACTTTGGCTGGGGAGTACTTCTTGCAAGCAGAGCTGACAAGCAACG
// TCTTAAAAACAGGCGTGGTGCGCTGCTGCGTGGGACAGTGCAACAATGCCATCCCTGTTGACACCGTCCTTACCATGA
// AGAAGCTGCCCATCACCTATAGCAACAGGAAGGAAAACAAGGGGGGCTACCTCTGCCACTCCTGTGCGGAGCAGCGCA
// TCGGGCCCCTGGCGTTCCTGACAGCCTCCCCGGAGCAGGTGCGCGCCATGGAGCGCACTGTGGAGAACATCGTACTGC
// CCCGGCATGAGGCCCTGCTCTTCCTCGTCTTCTGAGGACAAGGCGCACGTTCTCCGCAGTGCAGAGCAGCTTGCCGAG
// GACCCCGCGTGAAGAAAGCCAGCGAGGGGGGCTTCTGCTCCCTGAGACTCTGGGTTCACCCACAGCACTTTCTGAGGA
// AGAGGACACCAGCCCAAGCTGGACCTGCCATTTCTCCACTCCCTACAGACAGCCAGTCTCCACTTGCCTCCCCTCTGG
// ATGTATCTGGTCAGGGAAGTGGGGGATGTTCTTTTGATAAAAAAAAAAAAAAAAATTTATGTATTTAAACTTTTATTA
// CAAGATTTCAATTAAACAGGCACCATAGCACTGGCA`,
      tableIndex: ["High", "Medium", "Low"],
      table: [
        {
          index: "High",
          cutoff: ">0.822",
          precision: "0.900",
          specificity: 0.999,
          sensitivity: 0.972,
          accuracy: 0.999,
          mcc: 0.929,
        },
        {
          index: "Medium",
          cutoff: ">0.683",
          precision: "0.850",
          specificity: 0.999,
          sensitivity: 0.979,
          accuracy: 0.999,
          mcc: 0.912,
        },
        {
          index: "Low",
          cutoff: ">0.520",
          precision: "0.800",
          specificity: 0.999,
          sensitivity: 0.983,
          accuracy: 0.999,
          mcc: 0.887,
        },
      ],
    };
  },
  methods: {
    thresold(index) {
      return this.tableIndex[index];
    },
    jumpTo(index, indexPath) {
      console.log(index);
      console.log(indexPath);
      console.log(this.$refs);
      this.$refs[index].scrollIntoView(true);
    },
    test(e) {
      console.log(e);
    },
  },
};
</script>

<style lang="scss" scoped>
.helpTitle {
  height: 120px;
  // background: linear-gradient(to bottom, #344872 0%, #69cbf1 100%);
  // width: 100%;
  // margin: 60px auto 20px;
  border: 0;
  color: #15518d;
  text-align: center;
  font-family: "Roboto", sans-serif, cursive;
}
.title {
  text-align: left;
  position: relative;
  font-size: 50px;
  font-family: "Roboto", sans-serif, cursive;
  font-weight: bold;
  top: 50%;
  padding-left: 10%;
  transform: translateY(-50%);
}
#help-menu {
  height: 100%;
  padding-left: 30px;
  #navigation-title {
    margin: 30px 0px 10px;
    padding-left: 10px;
  }
  #navigation-menu {
    //导航栏部分
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    border: 0;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    li {
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      justify-content: center;
      font-size: 20px;
      width: 100%;
      // white-space: normal;
      padding-left: 10px !important;
      // background: #FFF !important;

      p {
        line-height: 56px;
      }
    }
    .is-active {
      background: #fff;
    }
  }
}
#help-content {
  margin-top: 25px;
  text-align: left;
  .help-title {
    font-size: 30px;
    margin: 15px 0;
    padding-bottom: 15px;
    border-bottom: 2px solid#15518d;
  }
  .help-section {
    margin: 30px 0;
  }
  p {
    font-size: 18px;
    // line-height: 30px;
  }
  #example-seq {
    width: 710px;
    height: 500px;
    word-wrap: break-word;
    white-space: pre-wrap;
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier,
      monospace;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    padding: 9.5px;
    line-height: 21px;
    overflow-y: auto;
  }
}
#example-title {
  margin: 10px 0;
}
/deep/ #help-text {
  margin-left: 20px;
}
/deep/ .t-header {
  color: #000;
  text-align: center;
}
/deep/ .performance-cell {
  text-align: center;
}
/deep/.cutoff {
  padding-left: 10px;
}
a {
  text-decoration: none;
  color: #337ab7;
}
</style>
