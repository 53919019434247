<template>
    <div>
        <div class="citationTitle">
            <div class="title">Please Cite</div>  
        </div>
        <div class="cardContainer">
                <el-card class="innerCard">
                    <div class="imgContainer">
                        <img class="coverImg" src="../assets/img/cover.png" alt="Frontiers in Cell and Developmental Biology"/>
                    </div>
                    <div class="articalDetail">
                        <h2>
                            <a class="articalTitle" href="https://doi.org/10.3389/fcell.2021.686894" target="_blank">
                                DeepOMe: A Web Server for the Prediction of 2′-O-Me Sites Based on the Hybrid CNN and BLSTM Architecture
                            </a>     
                        </h2>
                        <p class="author">
                            Hongyu Li, Li Chen, Zaoli Huang, Xiaotong Luo, Huiqin Li, Jian Ren*, Yubin Xie*.
                        </p>
                        <p>
                            <strong><i>Front Cell Dev Biol. </i></strong> 2021 May 14;9:686894. doi: 
                            <a target="_blank" class="link" rel="nofollow me noopener noreferrer" href="https://doi.org/10.3389/fcell.2021.686894">10.3389/fcell.2021.686894</a> 
                        </p>
                        <p>
                            [<a target="_blank" class="link" rel="nofollow me noopener noreferrer" href="https://doi.org/10.3389/fcell.2021.686894">Full Text</a>]  
                        </p>
                    </div>
                </el-card>
        </div>
    </div>
</template>

<script>

export default {
    name: "citation"
    
}
</script>

<style lang="scss" scoped>
.citationTitle {
    height: 120px;
    // background: linear-gradient(to bottom, #67427c 0%, #a04193 100%);
    width: 100%;
    // margin: 60px auto 0;
    border: 0;
    // color: #ffffff;
    text-align: center;
    font-family: "Roboto", sans-serif, cursive;
}
.title {
    text-align: left;
    position: relative;
    font-size: 50px;
    font-family: "Roboto", sans-serif, cursive;
    font-weight: bold;
    top: 50%;
    padding-left: 10%;
    transform: translateY(-50%);
    color: #15518d;
}
.outerCard {
    width: 80%;
    margin: 0 auto 60px;
}
.innerCard {
    margin: 20px auto;
    text-align: left;
    padding-left: 2%;
    border-left: #15518d 6px solid;
    font-size: 18px;
    width: 80%;
    margin: 0 auto 60px;
}
.imgContainer {
    float: left;
    padding-top: 28px;
    padding-bottom: 28px;
    
}
.coverImg {
    width: 140px;
    height: 185px;
    border: 1px solid #15518d;
}
.articalTitle {
    font-size: 22px;
    text-decoration: none;
    color: #333333;
    margin-top: 35px;
}
.articalDetail {
    margin-left: 180px;
    p, h2 {
      margin: 18px auto;
    }
}
.link {
    text-decoration: none;
    color: #1890ff;
    font-size: 16px;
    display: inline;
}
</style>