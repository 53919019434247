<template>
  <!-- <div id="app" style="min-width:1400px"> -->
  <div id="app">
    <web-header></web-header>
    <keep-alive>
    <router-view></router-view>
    </keep-alive>
    <!-- <router-link></router-link> -->
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <v-footer></v-footer>
  </div>
</template>

<script>
import Header from './components/Header'
import Home from './components/Home'
import Footer from './components/Footer'
export default {
  components:{
    'web-header':Header,
    'home':Home,
    'v-footer':Footer
  },
  methods: {
    test(e) {
      console.log("kmr");
      console.log(e);
    }
  },
}
</script>

<style lang="scss">
#app {
  position: relative;
  font-family: Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans",Helvetica Neue,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: calc(100% - 100px);
  padding-bottom: 100px;
  text-align: center;
  color: #2c3e50;
}
*{
  margin:0;
  padding:0
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.confirm-box {
  background-color: #fff;
  padding: 20px 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}
</style>
